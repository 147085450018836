<template>
  <div class="row">
    <div class="col-12">
      <c-card title="상세" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
            <c-plant 
              :disabled="true"
              type="edit" 
              name="plantCd" 
              v-model="eduInfo.plantCd" />
          </div>
          <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
            <c-datepicker
              :disabled="true"
              label="교육년도"
              type="year"
              name="educationYear"
              v-model="eduInfo.educationYear">
            </c-datepicker>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
            <c-text
              :disabled="true"
              label="교육과정"
              name="educationCourseName"
              v-model="eduInfo.educationCourseName">
            </c-text>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
            <c-text
              :disabled="true"
              label="교육종류1"
              name="educationKindCdLargeName"
              v-model="eduInfo.educationKindCdLargeName">
            </c-text>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
            <c-text
              :disabled="true"
              label="교육종류2"
              name="educationKindCdSmallName"
              v-model="eduInfo.educationKindCdSmallName">
            </c-text>
          </div>
        </template>
      </c-card>
      <c-card title="계획 대비 결과 추이 종합정보" class="cardClassDetailForm annual-education-trend-card">
        <template slot="card-detail">
          <div class="col-12">
            <span v-html="$comm.convertEnter(message)"></span>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-12">
      <c-table
        ref="table"
        title="연간 교육 계획에 포함된 교육일정"
        tableId="table"
        :columns="grid.columns"
        :data="eduTrend.planResults"
        :gridHeight="grid.height"
        :filtering="false"
        :checkClickFlag="false"
        :isExcelDown="true"
        @linkClick="linkClick"
      >
      </c-table>
    </div>
    <div class="col-12">
      <c-table
        ref="table"
        title="연간 교육 계획에 포함되지 않은 교육일정"
        tableId="table"
        :columns="grid.columns"
        :data="eduTrend.nonPlanResults"
        :gridHeight="grid.height"
        :filtering="false"
        :checkClickFlag="false"
        :isExcelDown="true"
        @linkClick="linkClick"
      >
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { toThousandFilter } from '@/utils/filter';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'annual-education-trend-analysis-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        eduEducationId: '',
        disabled: false,
        educationYear: '',
        eduCourseId: '',
        educationMonth: '',
        plantCd: '',
        educationCourseName: '',
        educationKindCdLargeName: '',
        educationKindCdSmallName: '',
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'educationCourseName',
            field: 'educationCourseName',
            label: '교육과정',
            align: 'center',
            style: 'width:120px', 
            sortable: false,
          },
          {
            name: 'educationName',
            field: 'educationName',
            label: '교육명',
            align: 'left',
            style: 'width:200px', 
            sortable: false,
          },
          {
            name: 'educationTypeName',
            field: 'educationTypeName',
            label: '교육구분',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'mainTargetAudience',
            field: 'mainTargetAudience',
            label: '주요 대상자',
            style: 'width:150px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'educationDate',
            field: 'educationDate',
            label: '교육기간',
            style: 'width:150px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'educationMethodName',
            field: 'educationMethodName',
            label: '교육방법',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'educationLocation',
            field: 'educationLocation',
            label: '교육장소',
            style: 'width:100px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'estimatedEducationExpenses',
            field: 'estimatedEducationExpenses',
            label: '예산',
            style: 'width:100px',
            align: 'right',
            sortable: false,
            type: 'cost',
          },
          {
            name: 'attendee',
            field: 'attendee',
            label: '교육인원',
            child: [
              {
                name: 'totalEduAttendeeCnt',
                field: 'totalEduAttendeeCnt',
                label: '총원',
                style: 'width:80px',
                align: 'right',
                sortable: false,
                type: 'cost',
              },
              {
                name: 'graduates',
                field: 'graduates',
                label: '이수',
                child: [
                  {
                    name: 'insideEduGraduatesCnt',
                    field: 'insideEduGraduatesCnt',
                    label: '내부',
                    style: 'width:80px',
                    align: 'right',
                    sortable: false,
                    type: 'cost',
                  },
                  {
                    name: 'outsideEduGraduatesCnt',
                    field: 'outsideEduGraduatesCnt',
                    label: '외부',
                    style: 'width:80px',
                    align: 'right',
                    sortable: false,
                    type: 'cost',
                  },
                  {
                    name: 'totalEduGraduatesCnt',
                    field: 'totalEduGraduatesCnt',
                    label: '총원',
                    style: 'width:80px',
                    align: 'right',
                    sortable: false,
                    type: 'cost',
                  },
                ]
              },
              {
                name: 'fail',
                field: 'fail',
                label: '미이수',
                child: [
                  {
                    name: 'insideEduFailCnt',
                    field: 'insideEduFailCnt',
                    label: '내부',
                    style: 'width:80px',
                    align: 'right',
                    sortable: false,
                    type: 'cost',
                  },
                  {
                    name: 'outsideEduFailCnt',
                    field: 'outsideEduFailCnt',
                    label: '외부',
                    style: 'width:80px',
                    align: 'right',
                    sortable: false,
                    type: 'cost',
                  },
                  {
                    name: 'totalEduFailCnt',
                    field: 'totalEduFailCnt',
                    label: '총원',
                    style: 'width:80px',
                    align: 'right',
                    sortable: false,
                    type: 'cost',
                  },
                ]
              },
            ]
          },
        ],
        data: [],
        height: '300px'
      },
      searchParam: {
        plantCd: null,
        educationYear: '',
        educationKindCdSmall: null,
        educationKindCdLarge: null,
        educationTypeCd: null,
        legalEduYn: null,
      },
      eduInfo: {
        plantCd: null,
        educationYear: '',
        educationCourseName: '',
        educationKindCdLargeName: '',
        educationKindCdSmallName: '',
      },
      eduTrend: {
        sumEstimatedEducationExpenses: 0,
        totalEduAttendeeCnt: 0,
        insideEduGraduatesCnt: 0,
        outsideEduGraduatesCnt: 0,
        totalEduGraduatesCnt: 0,
        insideEduFailCnt: 0,
        outsideEduFailCnt: 0,
        totalEduFailCnt: 0,
        planResults: [],
        nonPlanResults: [],
      },
      searchYear: '',
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    message() {
      let returnText = ``;
      if (this.eduTrend) {
        if (this.eduTrend.sumEstimatedEducationExpenses > 0) {
          returnText = `✔ ${this.$language('예산계획금액이 증가하였습니다.')}`
            + ` [ <font class="text-negative" style="font-weight:800;">${toThousandFilter(this.eduTrend.sumEstimatedEducationExpenses)} </font> ]<br/>`;
        } else {
          returnText = `✔ ${this.$language('예산계획금액 변동없습니다.')}<br/>`;
        }
        if (this.eduTrend.totalEduAttendeeCnt > 0) {
          returnText += `✔ ${this.$language('교육인원이 증가하였습니다.')}`
            + ` [ <font class="text-negative" style="font-weight:800;">${this.eduTrend.totalEduAttendeeCnt} </font> ]<br/>`
            + `✔ ${this.$language('교육 이수한 인원은 (내부/외부/총원) 증가하였습니다.')}`
            + ` [ <font class="text-teal" style="font-weight:800;">${this.eduTrend.insideEduGraduatesCnt} / ${this.eduTrend.outsideEduGraduatesCnt} / ${this.eduTrend.totalEduGraduatesCnt}</font> ]<br/>`
            + `✔ ${this.$language('교육 이수하지 못 한 인원은 (내부/외부/총원) 증가하였습니다.')}`
            + ` [ <font class="text-negative" style="font-weight:800;">${this.eduTrend.insideEduFailCnt} / ${this.eduTrend.outsideEduFailCnt} / ${this.eduTrend.totalEduFailCnt}</font> ]<br/>`;
        } else {
          returnText += `✔ ${this.$language('교육인원 변동없습니다.')}`
        }
      }
      return returnText;
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.getUrl = selectConfig.sop.edu.annual.trend.get.url;
      // list setting
      if (this.popupParam.eduCourseId) {
        this.eduInfo.plantCd = this.popupParam.plantCd
        this.eduInfo.educationYear = this.popupParam.educationYear
        this.eduInfo.educationCourseName = this.popupParam.educationCourseName
        this.eduInfo.educationKindCdLargeName = this.popupParam.educationKindCdLargeName
        this.eduInfo.educationKindCdSmallName = this.popupParam.educationKindCdSmallName
      }
      this.getList();
    },
    getList() {
     if (this.eduInfo.plantCd && this.eduInfo.educationYear) {
        this.$http.url = this.getUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.eduInfo.plantCd,
          educationYear: this.eduInfo.educationYear,
          eduCourseId: this.popupParam.eduCourseId,
          educationMonth: this.popupParam.educationMonth,
        };
        this.$http.request((_result) => {
          this.$_.extend(this.eduTrend, _result.data)
        },);
      } else {
        Object.assign(this.$data.eduTrend, this.$options.data().eduTrend);
      }
    },
    linkClick(row, col, index) {
      this.popupOptions.title = "교육계획 및 결과 상세"; 
      this.popupOptions.param = {
        eduEducationId: row.eduEducationId ? row.eduEducationId : '',
        stepCd: row.documentStatusCd === 'ESC000001' ?  'ESC0000005' : 'ESC0000010',
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/sop/edu/result/educationResultDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
<style lang="sass">
.annual-education-trend-card
  .customCardbody
    padding-top: 0px !important
</style>